import React from "react"
import { RichText } from "prismic-reactjs"
import Carousel from "nuka-carousel"
import { AnimatePresence, motion } from "framer-motion"
import { Container, Row, Col } from "react-grid-system"

import "./Project.scss"
import {
  PrismicProject,
  PrismicImage,
  PrismicFluidImage,
} from "../types/prismic"
import ChevronDown from "../assets/icons/chevron-down-small.svg"
import ChevronUp from "../assets/icons/chevron-up-small.svg"
import CircleArrowRight from "../assets/icons/circle-arrow-right.svg"
import CircleArrowLeft from "../assets/icons/circle-arrow-left.svg"
import NavBars from "./NavBars"
import { useWindowSize, isBreakpointMore } from "../hooks/WindowSize"
import Copyright from "./Copyright"
import LazyImage from "./LazyImage"

const getCats = (project: PrismicProject) => {
  return project.project_types
    .map(type => {
      return RichText.asText(type.type.title)
    })
    .join(", ")
}

interface ImageSlideProps {
  image: {
    image: PrismicImage
    imageSharp?: PrismicFluidImage
  }
}

type Props = {
  project: PrismicProject
  returnTo: string
  closeAction?(): void
}
const MobileView: React.FC<Props> = ({ project, returnTo, closeAction }) => {
  return (
    <div className="project">
      <div className="background">
        <div>
          <div className="project__header padding--content">
            <div className="d--flex flex-wrap--nowrap">
              <div className="flex--fill">
                <h1 className="project__title heading--4">
                  {project.title ? RichText.asText(project.title) : ""}
                </h1>
                <div className="project__types heading--4 text--light">
                  {getCats(project)}
                </div>
              </div>
              <div>
                <NavBars to={returnTo} action={closeAction} iconState="close" />
              </div>
            </div>
          </div>
          <Carousel
            wrapAround={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <button onClick={previousSlide} className="project__slide-ctrl">
                <CircleArrowLeft />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button onClick={nextSlide} className="project__slide-ctrl">
                <CircleArrowRight />
              </button>
            )}
            renderBottomCenterControls={null}
          >
            {project.images.map(img => (
              <div className="project__image-slide" key={img.image.url}>
                <LazyImage image={img.image} key={img.image.url} background />
              </div>
            ))}
          </Carousel>
          <div className="padding--content">
            <div className="project__content">
              {RichText.render(project.content)}
            </div>
            {/* <TransitionLink
              to={returnTo}
              className="d--block text-align--center"
            >
              <ChevronDown />
              <div className="heading--4 text--light">Info</div>
            </TransitionLink> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const DesktopView: React.FC<Props> = ({ project }) => {
  const [showContent, setShowContent] = React.useState(false)

  const toggleContent = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowContent(!showContent)
  }

  return (
    <div className="project position--relative">
      <div className="background">
        <div className="vh--100 d--flex flex-direction--column flex-wrap--nowrap">
          <div className="flex--fill">
            <Carousel
              className="project__carousel"
              wrapAround={true}
              renderCenterLeftControls={({ previousSlide }) => (
                <button onClick={previousSlide} className="project__slide-ctrl">
                  <CircleArrowLeft />
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button onClick={nextSlide} className="project__slide-ctrl">
                  <CircleArrowRight />
                </button>
              )}
              renderBottomCenterControls={null}
            >
              {project.images.map(img => (
                <LazyImage image={img.image} key={img.image.url} background />
              ))}
            </Carousel>
          </div>
          <div className="project__header">
            <div className="d--flex flex-wrap--nowrap">
              <div className="flex--fill">
                <h1 className="project__title heading--4">
                  {project.title ? RichText.asText(project.title) : ""}
                </h1>
                <div className="project__types heading--4 text--light">
                  {getCats(project)}
                </div>
              </div>
              <div className="text-align--center">
                <a href="#" onClick={toggleContent}>
                  <ChevronUp />
                  <div className="heading--4 text--light">Info</div>
                </a>
              </div>
              <div className="flex--fill align-self--flex-end">
                <div className="text-align--right">
                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {showContent && (
            <motion.div
              className="project__content-motion theme-light"
              animate={{ y: "0" }}
              exit={{ y: "100%" }}
              initial={{ y: "100%" }}
              transition={{ ease: "easeInOut" }}
            >
              <div className="project__content-motion-inner">
                <div className="text-align--center project__content-motion-trigger">
                  <a href="#" onClick={toggleContent}>
                    <div className="heading--4 text--light">Info</div>
                    <ChevronDown />
                  </a>
                </div>
                <Container fluid>
                  <Row>
                    <Col>
                      <h1 className="project__title heading--1 text--bold">
                        {project.title ? RichText.asText(project.title) : ""}
                      </h1>
                      <div className="project__types heading--1 text--light">
                        {getCats(project)}
                      </div>
                    </Col>
                    <Col>
                      <div className="project__content">
                        {RichText.render(project.content)}
                      </div>
                    </Col>
                    <Col className="hide--lg-down"></Col>
                  </Row>
                </Container>
                <div className="text-align--right project__copyright">
                  <Copyright />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

const Project: React.FC<Props> = props => {
  const windowSize = useWindowSize()
  const useDesktop = isBreakpointMore("lg", windowSize)
  if (useDesktop) return <DesktopView {...props} />
  return <MobileView {...props} />
}

export default Project
