import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import { PrismicQuery, PrismicEdges, PrismicProject } from "../types/prismic"
import { useNavTree } from "../hooks/NavTreeState"
import { getRouteFromTree } from "../util/navTree"
import Project from "./Project"
import { useSetRouteNavState } from "../hooks/NavState"
import SEO from "./Seo"

// define types
type AllPages = {
  allProjects: PrismicEdges<PrismicProject>
}

export const query = graphql`
  query ProjectQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            content
            images {
              image
            }
            project_types {
              type {
                ... on PRISMIC_Project_type {
                  title
                }
              }
            }
            meta_description
          }
        }
      }
    }
  }
`

const ProjectPage: React.FC<PrismicQuery<AllPages>> = ({
  data: prismicData,
}) => {
  const data = prismicData.prismic.allProjects.edges?.[0]?.node
  if (!data) return null

  // find the route to return to
  const navTree = useNavTree()
  const route = getRouteFromTree(navTree, "project", data._meta.uid)

  useSetRouteNavState("compact", "entered", {
    to: route,
    icon: "back",
  })

  return (
    <div className="project__wrap theme-light">
      <SEO
        title={RichText.asText(data.title)}
        description={data.meta_description}
      />
      <Project project={data} returnTo={route} />
    </div>
  )
}

export default ProjectPage
